<template>
  <div class="page">
    <about
      text1="Hi, my name is"
      text2="Mehdi Bourahla."
      text3="Fulbright Scholar and ML/AI Scientist."
      text4="Graduated from the University of North Carolina at Charlotte with a Master's degree."
      linkTo="engineer"
      :imgSrc="
        require('@/assets/img/engineer/mohamed-mehdi-bourahla-uncc-machine-learning.jpg')
      "
    ></about>
    <soft-work></soft-work>
    <soft-portfolio></soft-portfolio>
    <projects sectionTitle="Other Projects" :projects="projects"></projects>
    <contact></contact>
  </div>
</template>

<script>
import About from "../components/About.vue";
import SoftWork from "../components/Software/SoftWork.vue";
import SoftPortfolio from "../components/Software/SotfPortfolio.vue";
import Projects from "../components/Projects.vue";
import Contact from "../components/Contact.vue";
export default {
  name: "Engineer",
  components: {
    About,
    SoftWork,
    SoftPortfolio,
    Projects,
    Contact,
  },
  data() {
    return {
      projects: [
        {
          title: "Reminder App",
          description:
            "This project was thought as a social media where users posts messages called Reminders while others can follow, like, favourite these reminders. Developed with Laravel and VueJS.",
          links: {
            github: "https://github.com/mehdibourahla/reminder-laravel",
          },
          tags: ["PHP", "Laravel", "VueJS"],
        },
        {
          title: "PongJS",
          description:
            "PongJS is the well known Pong game developed with JavaScript where the user has control over a board and plays against an AI.",
          links: {
            github: "https://github.com/mehdibourahla/PongJS",
            production: "pongman.netlify.app",
          },
          tags: ["JavaScript"],
        },
        {
          title: "GoodBoy List",
          description:
            "Goodboy List is a Todo list application where lives a cute dog, and users have to do their task to make the dog happy.",
          links: {
            github: "https://github.com/mehdibourahla/goodboylist",
            production: "https://goodboylist.herokuapp.com/login",
          },
          tags: ["NodeJS", "ExpressJS", "MongoDB", "Bootstrap"],
        },
        {
          title: "Contact Keeper",
          description:
            "Full stack MERN contact manager with React hooks, context & JWT authentication. Developed while following a course on React done by Brad Traversy.",
          links: {
            github:
              "https://github.com/mehdibourahla/bradtraversy-react-contactKeeper",
            production: "https://contactkeeper-brhl.herokuapp.com/login",
          },
          tags: ["MongoDB", "ExpressJS", "ReactJS", "NodeJS"],
        },
        {
          title: "Personal Blog",
          description:
            "Simple Blog application developped with NodeJS, manages users, articles and comments. Set permissions to each users and configured routes with middlewares.",
          links: {
            github: "https://github.com/mehdibourahla/Blog",
            production: "https://mehdibourahla.herokuapp.com/blogs",
          },
          tags: ["NodeJS", "ExpressJS", "MongoDB", "Bootstrap"],
        },
        {
          title: "Algerian Railways",
          description:
            "Algerian Railways is an online plateform for train reservation made for three actors that are the administrator to manage the trains, the consultant to track data and the customers to book reservations. ",
          links: {
            github: "https://github.com/mehdibourahla/Algerian-Railways",
          },
          tags: ["PHP", "HTML/CSS", "Bootstrap"],
        },
      ],
    };
  },
};
</script>
SoftAbout

<style></style>
