<template>
  <div class="relative bg-black text-white">
    <div class="flex flex-col items-center text-center py-16">
      <h1 class="text-4xl font-bold">Follow me on Social Media!</h1>
      <div
        class="flex justify-center items-center h-48 space-x-6 md:space-x-32"
      >
        <a href="https://www.youtube.com/channel/UCmj1p0fQNivPBc-eZNxmNMQ">
          <i class="fab fa-youtube fa-3x hover:text-red"></i>
        </a>
        <a href="https://twitter.com/MehdiBourahla">
          <i class="fab fa-twitter fa-3x hover:text-blue-600"></i>
        </a>
        <a href="https://www.instagram.com/newbiesoft/">
          <i class="fab fa-instagram fa-3x hover:text-red"></i>
        </a>
        <a href="https://www.linkedin.com/in/mehdibourahla/">
          <i class="fab fa-linkedin fa-3x hover:text-blue-600"></i>
        </a>
      </div>
      <button
        class="
          hover:text-4xl
          p-4
          text-3xl text-red
          border border-red
          rounded-lg
        "
        @click="redirectTo"
      >
        Get In Touch!
      </button>
    </div>
    <div class="text-center bottom-0 pb-2">
      <p>Designed and Developed by Mehdi Bourahla.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Contact",
  methods: {
    redirectTo() {
      console.log("HEY")
      this.$router.push({
        name: "contact",
      });
    },
  },
};
</script>

<style>
</style>