import Vue from "vue";
import VueRouter from "vue-router";
import Engineer from "../views/Engineer";
import Newbiesoft from "../views/Newbiesoft";
import Member from "../views/Member";
import Contact from "../views/Contact";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "engineer",
    component: Engineer,
  },
  {
    path: "/newbiesoft",
    name: "newbiesoft",
    component: Newbiesoft,
  },
  {
    path: "/member",
    name: "member",
    component: Member,
  },
  {
    path: "/contact",
    name: "contact",
    component: Contact,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.PUBLIC_URL,
  routes,
});

export default router;
