<template>
  <div class="bg-black text-white py-16">
    <div class="px-4 lg:px-32">
      <div class="lg:flex lg:items-center">
        <div class="lg:ml-1 w-1/4 h-px border border-red lg:order-2"></div>
        <h1 class="text-2xl font-semibold">Some things I've done</h1>
      </div>
      <template v-for="(portfolio, index) in portfolios">
        <div
          :key="index"
          class="py-8 lg:flex lg:justify-center lg:transform"
          :class="index % 2 ? 'lg:-translate-x-6' : 'lg:translate-x-6'"
        >
          <div class="w-full lg:max-w-xl" :class="{ 'order-1': index % 2 }">
            <img-fall-back :src="portfolio.img"></img-fall-back>
          </div>
          <div
            class="lg:transform lg:max-w-xl"
            :class="
              index % 2
                ? 'lg:translate-x-6 lg:text-left'
                : 'lg:-translate-x-6 lg:text-right'
            "
          >
            <small class="text-red font-semibold"> Featured project </small>
            <h2 class="text-2xl font-semibold">{{ portfolio.title }}</h2>
            <div class="bg-black-dark p-2 rounded-lg text-left">
              <p>{{ portfolio.description }}</p>
              <div v-if="portfolio.links">
                <a :href="portfolio.links.github">
                  <i class="fab fa-github fa-md mr-2"></i>
                </a>
                <a
                  v-if="portfolio.links.production"
                  :href="portfolio.links.production"
                >
                  <i class="fas fa-link fa-md"></i>
                </a>
              </div>
            </div>
            <div
              class="flex flex-wrap text-grey"
              :class="{ 'justify-end': !(index % 2) }"
            >
              <span
                v-for="(tag, index) in portfolio.tags"
                class="mr-1 mt-2 bg-trueGrey-700 p-1 rounded-lg"
                :key="index"
              >
                {{ tag }}
              </span>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import ImgFallBack from "../ImgFallBack.vue";
export default {
  name: "SoftPortfolio",
  components: {
    ImgFallBack,
  },
  data() {
    return {
      portfolios: [
        {
          img: require("@/assets/img/engineer/socialbit-social-interaction-mehdi-bourahla.jpg"),
          title: "SocialBit",
          description:
            "Contributed to SocialBit, an ML model using audio for predicting social interactions in hospital settings. Enhanced YAMNet with LSTM and Transformers, achieving 84% accuracy in detecting social interactions, I contributed to this project by developing an approach based on Ensemble Learning for Domain Adaptation. This approach was able to improve the performance of the model by 5% in terms of accuracy.",

          tags: [
            "Machine Learning",
            "Python",
            "PyTorch",
            "LSTM",
            "Transformers",
            "YAMNet",
            "Ensemble Learning",
          ],
        },
        {
          img: require("@/assets/img/engineer/tv-detector-sound-machine-learning-yamnet.png"),
          title: "TV Sound Detector",
          description:
            "This project is a TV broadcast sound detector. It was fine-tuned on the AudioSet dataset using the YAMNet model. An LSTM model was then trained to detect the TV sound. This model serves as an improvement to speech detection models that are not able to distinguish between speech and TV sound.",

          tags: [
            "Python",
            "Tensorflow",
            "Keras",
            "Machine Learning",
            "YAMNet",
            "LSTM",
          ],
        },
        {
          img: require("@/assets/img/engineer/social-interaction-quality-vuejs.jpg"),
          title: "Social Interaction Quality",
          description:
            "This is a Vue.js 3 web application built with Capacitor 3 and Vuetify that allows users to monitor the quality of their social interactions. The application records audio using the device's microphone and sends it to the Whisper API for transcription. The resulting transcript is then sent to ChatGPT to evaluate the quality of the social interaction on a scale of 1-100.",
          links: {
            github: "https://github.com/mehdibourahla/social-health",
          },
          tags: ["VueJS", "JavaScript", "Whisper API", "ChatGPT", "Capacitor"],
        },
        {
          img: require("@/assets/img/engineer/acile-vuejs-odoo-javascript.jpg"),
          title: "Acile",
          description:
            "The application makes it possible to connect any sales / distribution company with its current customers or potential buyers by offering them, directly from their mobile phones, access to its product catalog and to make their purchases.",
          links: {
            production:
              "https://play.google.com/store/apps/details?id=com.acile.app&pcampaignid=web_share",
          },
          tags: ["VueJS", "Odoo", "JavaScript", "Capacitor", "Python"],
        },
        {
          img: require("@/assets/img/engineer/SLR.png"),
          title: "Sign Language Recognition",
          description:
            "The main goal of this project is to conceive and realize a solution based on machine learning for sign language recognition that allows the control of a smart home environment through gestures.",
          links: {
            github:
              "https://github.com/mehdibourahla/Machine-Learning-for-Sign-Language-Recognition",
          },
          tags: ["Machine Learning", "Keras", "CNN", "RNN"],
        },
      ],
    };
  },
};
</script>

<style></style>
