<template>
<div class="page">
  <about
    text1="I try to be an active member of the society."
    text2="'A nation as a society forms a moral person"
    text3="and every member of it is personally responsible for his society.' - Thomas Jefferson"
    :imgSrc="require('@/assets/img/member/you-can-make-the-difference.jpg')"
  ></about>
  <member-portfolio></member-portfolio>
  <contact></contact>
</div>
  
</template>

<script>
import About from "../components/About.vue";
import Contact from "../components/Contact.vue";
import MemberPortfolio from "../components/Member/MemberPortfolio"
export default {
name:"Member",
components:{
  About,
  Contact,
  MemberPortfolio
}
}
</script>

<style>

</style>