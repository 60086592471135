<template>
  <div class="bg-white text-black py-16 px-4 lg:px-32">
    <div class="lg:flex lg:items-center">
      <div class="lg:ml-1 w-1/4 h-px border border-red lg:order-2"></div>
      <h1 class="text-2xl font-semibold">Some of my contributions</h1>
    </div>
    <template v-for="(portfolio, index) in portfolios">
      <div
        :key="index"
        class="py-8 lg:flex lg:justify-around lg:space-x-8"
        :class="{ 'lg:space-x-reverse': index % 2 }"
      >
        <div class="w-full lg:max-w-xl" :class="{ 'order-1': index % 2 }">
          <div class="grid grid-cols-2 gap-1">
            <img-fallback
              v-for="(img,index) in portfolio.imgs"
              :key="index"
              :src="img"
              class="border border-red h-40 w-96"
            ></img-fallback>
          </div>
        </div>
        <div class="lg:max-w-xl">
          <small class="text-red font-semibold"> Featured contribution </small>
          <h2 class="text-2xl font-semibold">{{ portfolio.title }}</h2>
          <div class="bg-black-dark text-white p-2 rounded-lg text-left">
            <p>{{ portfolio.description }}</p>
          </div>
          <div
            class="flex flex-wrap text-grey"
            :class="{ 'justify-end': !(index % 2) }"
          >
            <span
              v-for="(tag, index) in portfolio.tags"
              class="mr-1 mt-2 bg-trueGrey-700 p-1 rounded-lg"
              :key="index"
            >
              {{ tag }}
            </span>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import imgFallback from "../ImgFallBack";
export default {
  name: "SoftPortfolio",
  components: {
    imgFallback,
  },

  data() {
    return {
      isActive: false,
      portfolios: [
        {
          title: "Computer Science Community Club President",
          description:
            "The Computer Science Community Club (CSCC) is a university club that has more than 10 years of activity at Saad Dahbleb Blida University. Our activities can be summed up in the promotion of IT solutions through events such as GeekConf, CyberCrim, Webdays. As well as the training of our members in various fields and technologies (Web / Mobile Development, Robotics, Artificial Intelligence, etc.). ",

          tags: ["Computer Science", "Community", "University", "Club"],
          imgs: [
            require('@/assets/img/member/cscc-all-members.jpg'),
            require('@/assets/img/member/cscc-geek-conf.jpg'),
            require('@/assets/img/member/cscc-geek-conf-presentation.jpg'),
            require('@/assets/img/member/cscc-universitri.jpg'),
          ]
        },
        {
          title: "Junior Volunteer",
          description:
            "The EL BADR association is an association to help cancer patients, created on the initiative of citizens of the wilaya of Blida, including several doctors, in order to participate, as part of a collective effort, in better care for cancer patients. It strengthens the involvement of civil society in actions of a social and highly humanitarian nature.",

          tags: ["Cancer", "Volunteering", "Association"],
          imgs: [
            require('@/assets/img/member/ELBADR-randorose.jpg'),
            require('@/assets/img/member/ELBADR-CAC.jpg'),
            require('@/assets/img/member/ELBADR-enfant.jpg'),
            require('@/assets/img/member/ELBADR-exposant.jpg'),
          ]
        },
        {
          title: "Environmentalist",
          description:
            "I have always been concerned with the protection of the environment, and I have embarked on this path by organizing multiple events at the university level to educate students on the preservation of the environment. The last being UniversiTRI where we installed and sensitized the university body on the importance of selective sorting.",
          tags: ["Environment", "Green", "Protection"],
          imgs: [
            require('@/assets/img/member/nasa-echourouk.jpg'),
            require('@/assets/img/member/environement-startup.jpg'),
            require('@/assets/img/member/environment-clean.jpg'),
            require('@/assets/img/member/environment-universitri.jpg'),
          ]
        },
      ],
    };
  },
};
</script>

<style>
</style>