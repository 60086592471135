<template>
  <div class="page">
    <about
      class="flex-row-reverse"
      text1="Welcome to Newbiesoft!"
      text2="We break down the Technology"
      text3="That is making your life better"
      text4="I created Newbiesoft in October 2020 to share my passion about Technology. I create videos to explain basically new technologies like Artificial Intelligence, Cloud Computing, The Blockchain, etc."
      :imgSrc="require('@/assets/img/newbiesoft/newbiesoft.png')"
    ></about>
    <new-portfolio></new-portfolio>
    <projects class="pt-8" sectionTitle="Other Videos" :projects="projects"></projects>

    <contact></contact>
  </div>
</template>

<script>
import Contact from "../components/Contact.vue";
import About from "../components/About.vue";
import NewPortfolio from "../components/Newbiesoft/NewPortfolio.vue";
import Projects from "../components/Projects.vue";

export default {
  components: {
    Contact,
    About,
    NewPortfolio,
    Projects,
  },
  data() {
    return {
      projects: [
        {
          title: "Data is the new Oil !",
          description:
            "Who said that the crystal ball does not exist? Businesses are using data to explain the past and predict the future!",
          links: {
            youtube:
              "https://youtu.be/QdlfBauZRMw?list=PLD_BGArEI5JN_sUNE_t5OF5S3LyHTdibN",
          },
          tags: ["Data", "Data science", "Business Intelligence"],
        },
        {
          title: "Don't Buy a Nextgen Console!",
          description:
            "The PS5 and Xbox Series X|S are officially launched worldwide today ! Commercials on both sides hyped us up with GRAPHICS and PERFORMANCES (That we've seen years ago with a PC by the way), but still with an affordable price. ",
          links: {
            youtube:
              "https://youtu.be/1K5aetNjFYk?list=PLD_BGArEI5JN_sUNE_t5OF5S3LyHTdibN",
          },
          tags: ["PS5", "XBOX SERIES X", "NextGen"],
        },
        {
          title: "Our Future is in the Clouds!",
          description:
            "Imagine having the performances of thousands of powerful computers through a simple click or tap with your smartphone. Well that's what the cloud is all about !",
          links: {
            youtube:
              "https://youtu.be/j8-ayCagDUc?list=PLD_BGArEI5JN_sUNE_t5OF5S3LyHTdibN",
          },
          tags: ["Cloud", "Internet", "Hosting"],
        },
        {
          title: "MAKE MONEY ONLINE !",
          description:
            "Who hasn't already typed on google on how to make money online ? At least, I did. Every time you end up in some websites (Or some traps) where you're supposed to predict stock price to earn money 😂 Well don't worry, this podcast isn't about that since we'll be talking about Freelance jobs !",
          links: {
            youtube:
              "https://youtu.be/2c_b0lsuCWQ?list=PLD_BGArEI5JN_sUNE_t5OF5S3LyHTdibN",
          },
          tags: ["Freelance", "Money", "Online"],
        },
        {
          title: "MAKE YOUR HOME SMART !",
          description:
            "Have you ever wanted to awaken the force to turn up the lights or shut the doors ? Well young Padawan your time has come! Get you a Home assistant ! ",
          links: {
            youtube:
              "https://youtu.be/RbVH1yIf21c?list=PLD_BGArEI5JN_sUNE_t5OF5S3LyHTdibNs",
          },
          tags: ["Smart homes", "Domotics", "IoT"],
        },
        {
          title: "IS SOCIAL MEDIA TOXIC ?",
          description:
            "IT'S 2021! Time for reflecting about your new year resolutions. Maybe having some clarity in mind could be one of your objectives. In this episode of the PodTech Talk, with Mehdi and Wahib, we discuss the impact of Social media on our clarity and thoughts. ",
          links: {
            youtube:
              "https://youtu.be/JPb8gnSsSIo?list=PLD_BGArEI5JN_sUNE_t5OF5S3LyHTdibN",
          },
          tags: ["Social Media", "Toxic", "Discipline"],
        },
      ],
    };
  },
};
</script>

<style>
</style>