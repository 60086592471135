<template>
  <div class="bg-white py-16">
    <div class="px-4 lg:px-32">
      <div class="lg:flex lg:items-center">
        <div
          class="lg:ml-1 w-1/4 lg:w-1/6 h-px border border-red lg:order-2"
        ></div>
        <h1 class="text-2xl font-semibold">Where I've worked</h1>
      </div>
      <div class="flex-column lg:flex">
        <div class="flex justify-around flex-wrap lg:block lg:pr-8 my-5">
          <a
            v-for="(work, index) in works"
            :key="index"
            @click="toggleActive(index)"
            :class="{ 'bg-red text-white': work.isActive }"
            class="block font-semibold rounded-lg p-2"
            >{{ work.location }}</a
          >
        </div>
        <template v-for="(work, index) in works">
          <div
            v-if="work.isActive"
            :key="index"
            class="lg:flex lg:justify-between lg:w-full"
          >
            <div class="py-4 lg:w-2/3">
              <h1 class="text-lg font-semibold">
                {{ work.position }}
                <span class="text-red">{{ work.location }}</span>
              </h1>
              <small class="font-light">{{ work.duration }}</small>
              <ul class="font-light lg:text-xl">
                <li
                  v-for="(description, index) in work.jobDescription"
                  :key="index"
                >
                  <small>
                    <span class="p-2 material-icons text-red text-sm">
                      radio_button_checked
                    </span>
                    {{ description }}
                  </small>
                </li>
              </ul>
            </div>
            <img-fall-back class="lg:w-80" :src="work.jobImg"></img-fall-back>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import ImgFallBack from "../ImgFallBack.vue";
export default {
  name: "SoftWork",
  components: {
    ImgFallBack,
  },
  methods: {
    toggleActive(clickedInd) {
      this.works.map((work, index) => {
        if (clickedInd === index) {
          work.isActive = true;
        } else {
          work.isActive = false;
        }
      });
    },
  },
  data() {
    return {
      works: [
        {
          position: "Research Assistant",
          location: "UNC Charlotte",
          duration: "November 2022 - PRESENT",
          jobDescription: [
            "Developed and fine-tuned advanced machine learning algorithms, such as LSTM and Transformers, specifically for detecting social interactions via wearable technology in a significant NIH-funded project.",
            "Showcased deep expertise in Python, TensorFlow, and the YAMNet model to optimize the performance and accuracy of algorithms, achieving 84% accuracy.",
            "Efficiently managed data processing and model training using Bash scripts on computational clusters, enhancing project workflow and outcomes.",
          ],
          jobImg: require("@/assets/img/engineer/machine-learning.png"),
          isActive: true,
        },
        {
          position: "Lead Software Engineer",
          location: "SIG-Service",
          duration: "April 2021 - May 2022",
          jobDescription: [
            "Led the development of ERP systems for distribution companies using the Odoo framework and VueJS.",
            "Orchestrated a team of 3 developers (Backend, Frontend, UI/UX) and collaborated with the CTO in architectural design and implementation.",
            "Managed client interactions, including requirement analysis and training on new features, ensuring tailored solutions and customer satisfaction.",
          ],
          jobImg: require("@/assets/img/engineer/software-engineer.png"),
          isActive: false,
        },
        {
          position: "Machine Learning Engineer",
          location: "CERIST",
          duration: "September 2019 - September 2020",
          jobDescription: [
            "Developed innovative gesture recognition solutions using Wi-Fi signal processing, applying Python, Keras, and deep learning models (CNN, LSTM, ConvLSTM).",
            "Conducted a comparative study of various frameworks and tools for system implementation, showcasing versatility in TensorFlow, PyTorch, and Scikit-Learn.",
            "Implemented and tuned deep learning models for a smart home environment, employing TensorFlow Serving for model deployment and Flask for application development.",
          ],
          jobImg: require("@/assets/img/engineer/machine-learning.png"),
          isActive: false,
        },
      ],
    };
  },
};
</script>

<style></style>
