<template>
  <div
    class="bg-black text-white p-4 py-16 lg:px-32 lg:flex lg:justify-around lg:items-center"
  >
    <div class="lg:w-1/2 py-5">
      <p class="text-red">{{ text1 }}</p>
      <p class="text-white text-4xl">{{ text2 }}</p>
      <p class="text-grey text-3xl">{{ text3 }}</p>
      <p class="py-4 font-light text-grey text-2xl">
        {{ text4 }}
      </p>
    </div>
    <div class="border border-red rounded-lg relative lg:w-80 lg:h-80">
      <img-fall-back
        :src="imgSrc"
        class="transform translate-x-1 translate-y-1 absolute inset-0 w-full h-full object-cover rounded-lg"
      ></img-fall-back>
    </div>
  </div>
</template>

<script>
import ImgFallBack from "./ImgFallBack.vue";
export default {
  name: "About",
  props: {
    text1: String,
    text2: String,
    text3: String,
    text4: String,
    linkTo: String,
    imgSrc: String,
  },
  components: {
    ImgFallBack,
  },
};
</script>

<style></style>
