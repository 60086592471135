<template>
  <div class="w-full h-80 rounded-lg">
    <iframe
      class="w-full h-full rounded-lg"
      :src="src"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    ></iframe>
  </div>
</template>

<script>
export default {
  name: "YtbVideo",
  props: {
    src: String,
  },
};
</script>

<style>
</style>