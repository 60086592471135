<template>
  <div class="bg-white py-16">
    <div class="px-4 md:px-32">
      <div class="md:flex md:items-center">
        <div
          class="md:ml-1 w-1/4 md:w-1/6 h-px border border-red md:order-2"
        ></div>
        <h1 class="text-2xl font-semibold">{{ sectionTitle }}</h1>
      </div>
      <div
        class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-4"
      >
        <template v-for="(project, index) in projects">
          <div
            :key="index"
            class="flex flex-col justify-between bg-black p-3 text-white rounded-lg"
          >
            <div class="flex items-center">
              <span v-if="project.links.youtube" class="text-red">
                    <i class="fab fa-youtube fa-2x"></i>
              </span>
              <span v-else class="material-icons-outlined text-red">
                folder_open
              </span>
              <h2 class="font-semibold text-xl ml-2">
                {{ project.title }}
              </h2>
            </div>
            <p>{{ project.description }}</p>
            <div class="flex justify-between items-center my-2">
              <div class="flex flex-wrap text-grey">
                <span
                  v-for="(tag, index) in project.tags"
                  :key="index"
                  class="mr-1 mt-2 bg-trueGrey-700 p-1 rounded-lg"
                >
                  {{ tag }}
                </span>
              </div>
              <div class="flex">
                
                <a 
                  v-if="project.links.youtube"
                 :href="project.links.youtube">
                  <i class="fab fa-youtube fa-md"></i>
                </a>
                <a 
                  v-if="project.links.github"
                 :href="project.links.github">
                  <i class="fab fa-github fa-md"></i>
                </a>
                <a
                  v-if="project.links.production"
                  :href="project.links.production"
                >
                  <i class="fas fa-link fa-md ml-2"></i>
                </a>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Projects",
  props:{
    sectionTitle: {
      type: String,
      required: true
    },
    projects: {
      type: Array,
      required: true
    }
  },
};
</script>

<style>
</style>