<template>
  <div class="bg-white text-black pt-16 px-4 lg:px-32">
    <div class="lg:flex lg:items-center">
      <div class="lg:ml-1 w-1/4 h-px border border-red lg:order-2"></div>
      <h1 class="text-2xl font-semibold">Cool episodes I've done</h1>
    </div>
    <template v-for="(portfolio, index) in portfolios">
      <div
        :key="index"
        class="py-8 lg:flex lg:justify-center lg:space-x-8"
        :class="{ 'lg:space-x-reverse': index % 2 }"
      >
        <div class="w-full lg:max-w-2xl" :class="{ 'order-1': index % 2 }">
          <ytb-video :src="portfolio.links.youtube"></ytb-video>
        </div>
        <div class="lg:max-w-xl">
          <small class="text-red font-semibold"> Featured video </small>
          <h2 class="text-2xl font-semibold">{{ portfolio.title }}</h2>
          <div
            class="bg-black-dark text-white p-2 rounded-lg text-left"
          >
            <p>{{ portfolio.description }}</p>
            <div>
              <a :href="portfolio.links.youtube">
                <i class="fab fa-youtube fa-md mr-2 text-red"></i>
              </a>
            </div>
          </div>
          <div
            class="flex flex-wrap text-grey"
            :class="{ 'justify-end': !(index % 2) }"
          >
            <span
              v-for="(tag, index) in portfolio.tags"
              class="mr-1 mt-2 bg-trueGrey-700 p-1 rounded-lg"
              :key="index"
            >
              {{ tag }}
            </span>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import YtbVideo from "../YtbVideo.vue";
export default {
  name: "SoftPortfolio",
  components: {
    YtbVideo,
  },
  data() {
    return {
      portfolios: [
        {
          title: "Is Artificial Intelligence a villain ?",
          description:
            "Artificial Intelligence, Cyborgs, Robots … We’re used to see them as villains in some movies that you might have seen. Will it be the case one day? ",
          links: {
            youtube:
              "https://www.youtube.com/embed/_GQInG-iklg?list=PLD_BGArEI5JN_sUNE_t5OF5S3LyHTdibN",
          },
          tags: ["Artificial Intelligence", "Robots", "Machine Learning"],
        },
        {
          title: "Women In Tech!",
          description:
            "TECHNOLOGY HAS NO GENDER! The Tech industry is opened to every creative and willing person. In this episode, our guest, Imene Henni Mansour shares with us her experience as woman in the Tech industry.",
          links: {
            youtube:
              "https://www.youtube.com/embed/5r5Dpzc8aBk?list=PLD_BGArEI5JN_sUNE_t5OF5S3LyHTdibN",
          },
          tags: ["Technology", "Women", "Inspiration"],
        },
        {
          title: "YOU'RE NOT SAFE!",
          description:
            "Imagine what could happen if someone gets all your personal information that you have on your devices or on the applications that you're using !",
          links: {
            youtube:
              "https://www.youtube.com/embed/Jgcfg6XKL1Q?list=PLD_BGArEI5JN_sUNE_t5OF5S3LyHTdibN",
          },
          tags: ["Cybersecurity", "Hackers", "Digital data"],
        },
      ],
    };
  },
};
</script>

<style>
</style>