<template>
  <div class="bg-red opacity-80 p-4 py-16 lg:px-32">
    <div class="text-center">
      <span class="text-3xl text-white font-semibold uppercase"
        >Send me an E-mail!</span
      >
      <form class="pb-4">
        <div class="py-4">
          <input
            v-model="form.name"
            :class="formClass"
            placeholder="Name"
            type="text"
            name="name"
            id="name"
          />
        </div>
        <div class="py-4">
          <input
            v-model="form.email"
            :class="formClass"
            placeholder="E-mail"
            type="email"
            name="email"
            id="email"
          />
        </div>
        <div class="py-4">
          <input
            v-model="form.object"
            :class="formClass"
            placeholder="Object"
            type="text"
            name="object"
            id="object"
          />
        </div>
        <div class="py-4">
          <textarea
            v-model="form.message"
            placeholder="Message..."
            :class="formClass"
            name="message"
            id=""
            cols="30"
            rows="10"
          ></textarea>
        </div>
        <button class="rounded-lg bg-white text-2xl font-semibold p-4 w-40 float-right">Send</button>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      formClass: "p-4 w-full border border-black rounded-lg focus:border-red",
      form: {
        name: "",
        email: "",
        object: "",
        message: "",
      },
    };
  },
};
</script>

<style>
</style>